<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-probe-api";
import { onMounted, reactive, ref, watch } from "vue";
import MethodService from "../../service/MethodService";
import toastr from "toastr";
import { useRouter, useRoute } from "vue-router";
// pinia store
import { changeEdit } from "../../stores/changeEdit";
const storeChangeEdit = changeEdit();
import { langWeb } from "../../stores/lang";
const router = useRouter();
const routeURL = useRoute();
import { datePicker } from "../../stores/datePicker";
const storeDatePicker = datePicker();
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toUpperCase())
import { isHaveDataDashboard } from "../../stores/HaveDataDashboard";
const storeIsHaveDataDashboard = isHaveDataDashboard();
import DashboardCard from "../../components/DashboardCardComponent.vue";
import Loading from "../../components/loading-hello.vue";
import Swal from "sweetalert2";
import LanguageService from "../../service/LanguageService";
const items = reactive({ value: [] });
const indexCard = ref(0);
const titleCard = ref("");
const idNewCard = ref("");
const showSortCard = ref(false);
const loadingData = ref(false);
const loadingBtn = ref(false);
const isClickDownload = ref(true); // nút download đang hiển thị
const isBtnLoading = ref(false); // nút Đang download
const idRequestDownload = ref("");
const KeyReRenderCard = ref(0);
const idCardUC = ref();
const serverId = ref("");
const startDate = ref();
const stopDate = ref();
const dashboardType = ref("uc");
// lấy về danh sách card theo màn uc
const getUsercases = async () => {
  try {
    const response = await mushroom.usecase.listAsync(
      {
        filters: ["code=UC621"],
      },
      { enabledCache: false }
    );
    idCardUC.value = response.result[0].id;
    await getAllCard(idCardUC.value);
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};
const getAllCard = async (id) => {
  loadingData.value = true;
  items.value = [];
  try {
    const response = await mushroom.card.listAsync(
      {
        filters: ["uc_id=" + id],
      },
      { enabledCache: false }
    );
    if (response.result?.length > 0) {
      indexCard.value = response.result.length;
      response.result.forEach((item) => items.value.push(item));
      storeIsHaveDataDashboard.haveData();
    } else storeIsHaveDataDashboard.noData();
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  } finally {
    loadingData.value = false;
  }
};
const addCard = () => {
  const addDomCard = {
    id: Math.random() + "",
  };
  Swal.fire({
    title: LanguageService[lang.value]?.t_input_card_title ?? "t_input_card_title",
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    confirmButtonText: LanguageService[lang.value].swal_add,
    icon: "info",
    showLoaderOnConfirm: true,
    cancelButtonText: LanguageService[lang.value].swal_cancel,
    showCancelButton: true,
    allowOutsideClick: false,
    preConfirm: (title) => {
      if (!title) {
        Swal.showValidationMessage(LanguageService[lang.value]?.t_please_enter_card_title ?? "t_please_enter_card_title");
      } else {
        titleCard.value = title;
        indexCard.value = indexCard.value + 1;
        addDomCard.title = title;
      }
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      await saveCard(titleCard.value, indexCard.value);
      addDomCard.id = idNewCard.value;
      await getAllCard(idCardUC.value);
    }
  });
};
const saveCard = async (titleCard, indexCard) => {
  const card_object = {
    dashboard: "uc",
    title: titleCard,
    index: indexCard,
    header: {
      is_hidden: false,
    },
  };
  try {
    const newId = await mushroom.card.createAsync(card_object);
    if (newId) toastr.success(LanguageService[lang.value]?.toastr_add_card_success ?? "toastr_add_card_success");
    idNewCard.value = newId.result;
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const reloadGridDashboard = () => {
  loadingData.value = true;
  getAllCard(idCardUC.value);
};
const reloadGrid = () => {
  loadingData.value = true;
};
const showSortCardTable = () => {
  showSortCard.value = !showSortCard.value;
  window.scrollTo(0, 0);
  document.body.classList.add("noScoll");
};

const reloadingData = () => {
  loadingData.value = true;
};
const reRenderCard = () => {
  KeyReRenderCard.value += 1;
};
const reRenderCardAfterOtionCard = async () => {
  await getUsercases();
};
const unloadingData = () => {
  setTimeout(() => {
    loadingData.value = false;
  }, 2000);
};
const downloadCard = async () => {
  loadingBtn.value = true;
  isClickDownload.value = false;
  isBtnLoading.value = true;
  storeIsHaveDataDashboard.downloadData();
  const search = location.search.substring(1);
  const data = search ? JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) }) : '';

  // 1. gửi lên để có id request
  const request_download_dashboard_object = {
    probe_id: localStorage.pid,
    type: "uc",
    uc_id: idCardUC.value,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
    data: search ? JSON.stringify(data) : undefined,
  };
  try {
    const newId = await mushroom.request_download_dashboard.createAsync(
      request_download_dashboard_object
    );
    if (newId.result) {
      idRequestDownload.value = newId.result;
    }
    // 2. nếu request thành công, check trạng thái 5s 1 lần
    if (idRequestDownload.value.length > 0) {
      window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    } else clearTimeout(window.checkStatusDownloadProbe);
  } catch (e) {
    console.error("Có lỗi: %o", e);
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(e.detail);
  }
};
const checkStatus = async () => {
  let response;
  try {
    response = await mushroom.request_download_dashboard.findByIdAsync(
      {
        id: idRequestDownload.value,
      },
      { enabledCache: false }
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    return;
  }

  if (response.result.status == "error") {
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(response.result.note);
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }

  if (response.result.status == "completed") {
    await MethodService.downloadFileDashboard(
      idRequestDownload.value,
      "dashboard_data.xlsx"
    );
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }
  window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
};

watch(
  () => storeChangeEdit.showEdit,
  async (newCenter) => {
    console.log("123", newCenter);
    await getAllCard(idCardUC.value);
  }
);

watch(
  () => storeDatePicker.showDatePicker,
  async () => {
    const search = location.search.substring(1);

    let queryForPush = Object.fromEntries(new URLSearchParams(search));
    queryForPush.start_date = MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[0]
    );
    queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[1]
    );
    queryForPush.ip = serverId.value;

    router
      .replace({
        name: "Usecases621",
        query: queryForPush,
      })
      .catch(() => {});
    if (idCardUC.value) await getAllCard(idCardUC.value);
  }
);

onMounted(async () => {
  if (routeURL.query?.start_date) {
    startDate.value = routeURL.query.start_date;
    stopDate.value = routeURL.query.stop_date;
    serverId.value = routeURL.query.ip;
    localStorage.setItem("dateSelect", [startDate.value, stopDate.value]);
    storeDatePicker.setDatePicker([startDate, stopDate]);
  }
  await getUsercases();
});
</script>

<template>
  <Layout>
    <div class="container-fluid dashboard-all">
      <loading v-if="loadingData"></loading>

      <div v-for="item in items.value" :key="item.id">
        <dashboard-card
          :cardId="item.id"
          :cardTitle="item.title"
          :cardIndex="item.index"
          :dashboardType="dashboardType"
          :headerOptions="item?.header"
          :typeDashboard="'uc'"
          @reloadGrid="reloadGrid"
          @reloadGridDashboard="reloadGridDashboard"
          @reloadingData="reloadingData"
          @unloadingData="unloadingData"
          @showSortCardTable="showSortCardTable"
          @reRenderCard="reRenderCard"
          @showDialogDashboardUC53="showDialogDashboardUC53"
          @reRenderCardAfterOtionCard="reRenderCardAfterOtionCard"
          :key="KeyReRenderCard"
        ></dashboard-card>
      </div>
      <div
        class="add-card d-flex justify-content-end"
        v-if="storeChangeEdit.showEdit === true && items.value.length === 0"
      >
        <el-button
          @click="addCard"
          type="button"
          style="height: 40px"
          class="btn btn-secondary btn-border"
        >
          {{ $t("t-add-card") }}
        </el-button>
      </div>
      <div
        class="add-card d-flex justify-content-end"
        v-if="storeChangeEdit.showEdit === false && items.value.length > 0"
      >
        <div>
          <el-button
            v-if="isClickDownload"
            @click="downloadCard"
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border button-dashboard hide"
          >
            {{ $t("t-download") }}
          </el-button>
          <el-button
            v-if="isBtnLoading"
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border button-dashboard hide"
            :loading="loadingBtn"
          >
            {{$t('t_loading')}}...
          </el-button>
        </div>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./usecase.scss" />
